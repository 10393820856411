import { AdPositions } from 'googlead/types';
import { adId } from 'googlead/utils';
import { buildAdUnitPath } from 'googlead/buildAdUnitPath';
import { GOOGLE_AD_REFRESH_KEY, GOOGLE_AD_REFRESH_VALUE } from 'googlead/constants';

export const AdUnitMobileBreakPoint = 992; // This var is inherited from bootstrap grid system
export const AdUnitTabletBreakPoint = 1200; // This var is inherited from bootstrap grid system
export const AdUnitLargeBreakPoint = 1400; // This var is inherited from bootstrap grid system

export const GPTAds: Record<AdPositions, (adUnitPath: string, targetId?: string) => () => googletag.Slot> = {
  [AdPositions.Fullscreen]: (adUnitPath: string) => () => googletag
    .defineSlot(adUnitPath, [1, 1], adId(AdPositions.Fullscreen))
    .addService(googletag.pubads())
    .setTargeting('p', [AdPositions.Fullscreen]), // p = Fullscreen
  [AdPositions.Popup]: () => () => googletag
    .defineOutOfPageSlot(buildAdUnitPath({ type: 'popup' }), adId(AdPositions.Popup))
    .addService(googletag.pubads())
    .setTargeting(GOOGLE_AD_REFRESH_KEY, GOOGLE_AD_REFRESH_VALUE)
    .setTargeting('p', [AdPositions.Popup]), // p = popup
  [AdPositions.Leaderboard]: (adUnitPath: string) => () => {
    const leaderboardMappingMid = googletag.sizeMapping()
      .addSize([AdUnitTabletBreakPoint, 200], [[970, 250]]) // Serve 970x250 starting with desktop width
      .addSize([0, 0], [[320, 100], [300, 100], [300, 50]]) // Mobile for rest
      .build();

    return googletag
      .defineSlot(adUnitPath, [[970, 250], [320, 100], [300, 100], [300, 50]], adId(AdPositions.Fullscreen))
      .defineSizeMapping(leaderboardMappingMid)
      .addService(googletag.pubads())
      .setTargeting(GOOGLE_AD_REFRESH_KEY, GOOGLE_AD_REFRESH_VALUE)
      .setTargeting('p', [AdPositions.Leaderboard]); // p = Leaderboard
  },
  [AdPositions.Regular]: (adUnitPath: string) => () => {
    const leaderboardMappingMid = googletag.sizeMapping()
      .addSize([AdUnitLargeBreakPoint, 200], [[970, 90], [728, 90]]) // Serve 970x90 starting with desktop width
      .addSize([AdUnitMobileBreakPoint, 200], [[728, 90]]) // Serve 728x90 starting with laptop width
      .addSize([0, 0], [[300, 250], [250, 250], [336, 280]]) // Mobile for rest
      .build();

    return googletag.defineSlot(adUnitPath, [[970, 90], [728, 90], [300, 250], [250, 250], [336, 280]], adId(AdPositions.Regular))
      .defineSizeMapping(leaderboardMappingMid)
      .addService(googletag.pubads())
      .setTargeting(GOOGLE_AD_REFRESH_KEY, GOOGLE_AD_REFRESH_VALUE)
      .setTargeting('p', [AdPositions.Regular]); // p = Mid
  },
  [AdPositions.RegularFirstOnPage]: (adUnitPath: string) => () => {
    const leaderboardMappingMid = googletag.sizeMapping()
      .addSize([AdUnitLargeBreakPoint, 200], [[970, 90], [728, 90]]) // Serve 970x90 starting with desktop width
      .addSize([AdUnitMobileBreakPoint, 200], [[728, 90]]) // Serve 728x90 starting with laptop width
      .addSize([0, 0], [[300, 250], [250, 250], [336, 280]]) // Mobile for rest
      .build();

    return googletag.defineSlot(adUnitPath, [[970, 90], [728, 90], [300, 250], [250, 250], [336, 280]], adId(AdPositions.RegularFirstOnPage))
      .defineSizeMapping(leaderboardMappingMid)
      .addService(googletag.pubads())
      .setTargeting(GOOGLE_AD_REFRESH_KEY, GOOGLE_AD_REFRESH_VALUE)
      .setTargeting('p', [AdPositions.RegularFirstOnPage]); // p = fpv
  },
  [AdPositions.Skyscraper]: (adUnitPath: string) => () => {
    const skyscraperMapping = googletag.sizeMapping()
      .addSize([AdUnitTabletBreakPoint + 1, 200], [[300, 600], [300, 250], [160, 600], [240, 600]]) // Serve Skysraper starting with width = 1201
      .addSize([AdUnitMobileBreakPoint, 200], [[160, 600], [120, 600]]) // Serve Skysraper thin starting with width = 992
      .addSize([0, 0], []) // Mobile for rest
      .build();

    return googletag.defineSlot(adUnitPath, [[300, 600], [300, 250], [160, 600], [240, 600], [120, 600]], adId(AdPositions.Skyscraper))
      .defineSizeMapping(skyscraperMapping)
      .addService(googletag.pubads())
      .setTargeting(GOOGLE_AD_REFRESH_KEY, GOOGLE_AD_REFRESH_VALUE)
      .setTargeting('p', [AdPositions.Skyscraper]); // p = Sky;
  },
};
