import axiosRetry from 'axios-retry';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { BUILD_LOCALE, IS_NODE_ENV_DEVELOP, IS_SSR } from 'ots-constants';
import { Diagnostics } from 'util/diagnostics';

export const CONTENT_TYPE: string = 'application/json';

/* eslint-disable no-underscore-dangle */

export const useProxy: boolean = process.env.NODE_ENV === 'development' || IS_SSR;

if (IS_SSR && !IS_NODE_ENV_DEVELOP) {
  // eslint-disable-next-line global-require
  const https = require('https');
  axios.defaults.httpsAgent = new https.Agent({ keepAlive: true });
  // axios.defaults.timeout = 60000;
}

let instance: AxiosInstance | undefined;

/**
 * @param example is a deprecated parameter that was used to select specific example from mock server
 * Not removed for sake of potentially turning mocks back on
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const axiosInstance = (example?: string) => {
  if (instance) {
    return instance;
  }
  const headers: { [key: string]: string } = {
    'Content-Type': CONTENT_TYPE,
    Accept: CONTENT_TYPE,
    locale: BUILD_LOCALE!,
  };

  instance = axios.create({
    headers,
  });

  instance.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError) => {
    const originalRequest = error.config;
    if (originalRequest) {
      if (error.response) {
        if (IS_SSR) {
          Diagnostics.debug(`[network] ${(new Date()).toISOString()}`);
        }
        Diagnostics.error({
          tag: 'network',
          message: `Request [${originalRequest.url}] failed`,
          data: error.response.data,
        });
        return Promise.reject(error);
      }
      if (error.message === 'Network Error' && typeof navigator !== 'undefined') {
        if (typeof navigator.onLine !== 'undefined' && !navigator.onLine) {
          // eslint-disable-next-line no-param-reassign
          error.response = {
            statusText: 'Device seems to be offline, check your network connection',
            config: originalRequest,
            headers: {},
            status: 800,
            data: {
              status: 800,
              message: 'Device seems to be offline, check your network connection',
            },
          };
        } else {
          // eslint-disable-next-line no-param-reassign
          error.response = {
            statusText: 'Please check your network connection',
            config: originalRequest,
            headers: {},
            status: 800,
            data: {
              status: 800,
              message: 'Please check your network connection',
            },
          };
        }
      }
      if (IS_SSR) {
        Diagnostics.debug(`[network] ${(new Date()).toISOString()}`);
      }
      Diagnostics.error({ tag: 'network', message: `Request [${originalRequest.url}] aborted`, data: originalRequest });
    } else {
      if (IS_SSR) {
        Diagnostics.debug(`[network] ${(new Date()).toISOString()}`);
      }
      Diagnostics.error({ tag: 'network', message: 'Unclear network error', data: error });
    }
    return Promise.reject(error);
  });
  if (IS_SSR) {
    axiosRetry(axios, {
      retries: 2,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: (error) => {
        if (IS_SSR) {
          Diagnostics.debug(`[network] Retry Request ${(new Date()).toISOString()}`, !error.response);
          Diagnostics.debug(error);
        }
        return !error.response;
      },
    });
  } else {
    axiosRetry(axios, { retries: 1 });
  }
  return instance;
};
