import { API_URL } from 'ots-constants';
import {
  HistoricalSnowfallType, OpenFlag, ResortStatus, ResortWeatherType, ReviewsOrderBy,
} from 'api/resort/types';
import { ResortTopRated } from 'api/home/types';
import { IGridSorting, SortingType } from 'components/common/Grid/types';
import { KayakServiceType } from 'util/kayakIntegrationUtils';

export const staticRoutes = {
  translations: () => `${API_URL}/api/v2/translations`,
  urlTranslations: () => `${API_URL}/api/v2/urls`,

  resortList: () => `${API_URL}/api/v2/resorts-all`,
  regionList: () => `${API_URL}/api/v2/regions`,
  newsCategoriesList: () => `${API_URL}/api/v2/news/categories`,

  /**
   * RESORTS
   */
  resortLastFirstHandReport: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/last-report`,
  resortReports: (resortUUID: string, page: number) => `${API_URL}/api/v2/resort/${resortUUID}/reports/pages/${page}`,
  resortReviewsPages: (
    resortUUID: string,
    page: number,
    orderBy: ReviewsOrderBy,
  ) => `${API_URL}/api/v2/resort/${resortUUID}/reviews/pages/${page}?orderBy=${orderBy}`,
  resortReviews: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/reviews`,
  resortDescriptionTabs: (resortUUID: string) => (
    `${API_URL}/api/v2/resort/${resortUUID}/descriptions`
  ),
  resortStrengths: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/strengths`,
  resortSnowReport: (resortUUID: string) => `${API_URL}/api/v3/resort/${resortUUID}/snowreport`, // NOTE: Version 3
  resortRelated: (resortUUID: string) => `${API_URL}/api/v3/resort/${resortUUID}/related`,
  resortSkiRentalsAlpin: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/skirentals/alpin`,
  resortShortInfo: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}`,
  resortShortWeatherInfo: (resortUUID: string) => `${API_URL}/api/v3/resort/${resortUUID}/shortreport`,
  resortFeaturedCam: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/featuredcam`,
  resortCameras: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/webcams`,
  resortWeatherItems: (resortUUID: string, type: ResortWeatherType.daily | ResortWeatherType.hourly) => (
    `${API_URL}/api/v3/resort/${resortUUID}/weather/${type}`
  ),
  resortSnowfallItems: (resortUUID: string, type: HistoricalSnowfallType.monthly | HistoricalSnowfallType.annual) => (
    `${API_URL}/api/v2/resort/${resortUUID}/snowfall/${type}`
  ),
  resortTrailMap: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/trailMap`,
  resortAccomodations: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/accomodations`,
  resortSubscribe: () => `${API_URL}/api/v2/subscribe`,
  resortRelatedNews: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/articles`,
  resortDailyLiftTickets: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/lift-tickets`,
  resortSeasonLiftTickets: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/season-passes`,
  /**
   * REGIONS
   */
  regionResortsByStatus: (
    regionUUID: string,
    status: OpenFlag | ResortStatus,
    page: number,
    limit: number,
    orderBy?: string,
    direction?: SortingType,
  ) => {
    const orderByQuery = orderBy ? `&orderBy=${orderBy}` : '';
    const directionQuery = direction ? `&direction=${direction}` : '';

    if (status === 'projected-closing') {
      return `${API_URL}/api/v2/region/${regionUUID}/resorts/closing-dates/page/${page}?limit=${limit}${orderByQuery}${directionQuery}`;
    }

    return `${API_URL}/api/v2/region/${regionUUID}/resorts/${status}/page/${page}?limit=${limit}${orderByQuery}${directionQuery}`;
  },
  regionRelated: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/related`,
  regionAllResortsTopSnow: (sorting?: IGridSorting) => {
    const orderByQuery = sorting?.orderBy ? `?orderBy=${sorting.orderBy}` : '';
    const directionQuery = sorting?.direction ? `&direction=${sorting.direction}` : '';

    return `${API_URL}/api/v2/snow/resorts${orderByQuery}${directionQuery}`;
  },
  regionRelatedNews: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/articles`,
  regionClosingOpenDates: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/opening-closing-dates`,
  regionMeta: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/meta`,
  resortMeta: (resortUUID: string) => `${API_URL}/api/v2/resort/${resortUUID}/meta`,
  regionFeaturedCam: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/featuredcam`,
  regionCameras: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/webcams`,
  regionCamerasByStatus: (
    regionUUID: string,
    status: OpenFlag | ResortStatus,
    page: number,
    limit: number,
  ) => `${API_URL}/api/v2/region/${regionUUID}/webcams/${status}/page/${page}?limit=${limit}`,
  regionFeatured: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/featured`,
  regionResortListGuide: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/guide`,
  regionFAQ: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/faq`,
  regionStats: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/stats`,
  regionAccommodations: (regionUUID: string) => `${API_URL}/api/v2/region/${regionUUID}/accomodations`,
  regionLiftTicketsPricesByResort: (
    regionUUID: string,
    page: number = 1,
    limit: number = 12,
    orderBy?: string,
    direction?: SortingType,
  ) => {
    const orderByQuery = orderBy ? `&orderBy=${orderBy}` : '';
    const directionQuery = direction ? `&direction=${direction}` : '';

    return `${API_URL}/api/v2/region/${regionUUID}/lift-tickets/page/${page}?limit=${limit}${orderByQuery}${directionQuery}`;
  },

  /**
   * HOME
   */
  search: (query: string) => `${API_URL}/api/v2/search?q=${query}`,
  searchPopular: () => `${API_URL}/api/v2/search/popular`,
  searchNews: (query: string) => `${API_URL}/api/v2/search/news?q=${query}`,
  backgroundImages: () => `${API_URL}/api/v2/backgroundImages`,
  snowWebcams: (limit: number) => `${API_URL}/api/v2/snow/webcams?limit=${limit}`,
  resortTopRating: (type: ResortTopRated) => `${API_URL}/api/v2/resort/topRated/${type}`,
  regionResortTopRating: (regionUUID: string, type: ResortTopRated) => (
    `${API_URL}/api/v2/region/${regionUUID}/resort/topRated/${type}`
  ),
  lastNews: () => `${API_URL}/api/v2/news/latestNews`,
  popularNews: () => `${API_URL}/api/v2/news/popularNews`,
  lastNewsWeather: () => `${API_URL}/api/v2/news/latestNews/weather`,
  newsFeatured: () => `${API_URL}/api/v2/news/featured`,
  newsFromCategory: (category: string, page: number) => `${API_URL}/api/v2/news/categories/${category}/${page}`,
  resortTopRatingPages: (
    type: ResortTopRated,
    page: number,
    limit?: number,
  ) => `${API_URL}/api/v2/region/resort/topRated/${type}?page=${page}${limit ? `&limit=${limit}` : ''}`,
  regionsListGuide: () => `${API_URL}/api/v2/guide/regions`,
  nearbyResorts: (
    lat: number,
    lon: number,
    orderBy?: string,
    direction?: SortingType,
  ) => {
    const orderByQuery = orderBy ? `&orderBy=${orderBy}` : '';
    const directionQuery = direction ? `&direction=${direction}` : '';

    return `${API_URL}/api/v2/resort/nearby/coords?lat=${lat}&lon=${lon}${orderByQuery}${directionQuery}`;
  },
  getMultipleResorts: (resortIds: Array<string>) => `${API_URL}/api/v2/resort/snowreports?resortIds=${resortIds.join(',')}`,
  guideHomeFeatured: () => `${API_URL}/api/v2/featured/guideHome`,
  snowWeatherHomeFeatured: () => `${API_URL}/api/v2/featured/snowWeatherHome`,

  contactInformation: (regionUUID: string) => `${API_URL}/api/v2/resort/${regionUUID}/contactInformation`,
  pricing: () => `${API_URL}/api/v2/tripadvisor/pricing`,
  unsubscribe: () => `${API_URL}/api/v2/unsubscribe`,
  kayakService: (resortUuid: string, type: KayakServiceType, latitude?: number, longitude?: number) => {
    const coords = !!latitude && !!longitude ? `&lat=${latitude}&lon=${longitude}` : '';

    return `${API_URL}/api/v2/resort/${resortUuid}/kayak?type=${type}${coords}`;
  },
  sendWidgetAgreement: () => `${API_URL}/api/v2/widget/terms-agreement`,
  getWidgetResorts: (resortsUUIDs: string) => `${API_URL}/api/v2/widget/resorts?ids=${resortsUUIDs}`,
  getWidgetRegion: (regionUUID: string, flag?: string) => `${API_URL}/api/v2/widget/${regionUUID}/resorts?status=${flag || 'all'}`,
};

export const dynamicRoutes = {};
