import { useEffect, useMemo, useRef } from 'react';
import assert from 'assert';
import classNames from 'classnames';

import { getLang, getLocale, getUnderscoreLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { isProductionServer } from 'ots-constants';

import { buildAdUnitPath } from 'googlead/buildAdUnitPath';
import { useAdUnitContext } from 'googlead/components/AdUnitContext';
import { ensureGPTInitalized } from 'googlead/ensureGPTInitalized';
import { AdUnitMobileBreakPoint, AdUnitTabletBreakPoint } from 'googlead/GPTAds';
import { createAd } from 'googlead/utils';
import { Diagnostics } from 'util/diagnostics';

import styles from './adunit.module.scss';

export const AdUnitClasses = styles;

export const isMobile = () => (global.innerWidth ?? 1200) < AdUnitMobileBreakPoint;
export const isTablet = () => !isMobile() && (global.innerWidth ?? 1200) < AdUnitTabletBreakPoint;

export const AdUnit = (
  {
    generator,
    className,
    mobile,
    desktop,
    recreateOnTablet,
  }: {
    generator: (adUnitPath: string) => () => googletag.Slot,
    className?: string,
    mobile?: boolean,
    desktop?: boolean,
    recreateOnTablet?: boolean,
  },
) => {
  const {
    region, resort, adType, adTKey, isReady, meta,
  } = useAdUnitContext();
  const locale = getLocale() as ELocale;
  const ref = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const lastWidth = useRef<number>((global.innerWidth ?? AdUnitTabletBreakPoint));
  const slot = useRef<googletag.Slot | null>(null);

  if (adType === 'resort') {
    assert(!!resort, 'Resort id should present when adType = resort');
    assert(!!region?.adCode, `Resort region adCode should present when adType = resort, check resort id=${resort.uuid}`);
  }
  if (adType === 'region') {
    assert(!!region?.adCode, `Region adCode should present when adType = region, check region id=${region?.uuid}`);
  }

  useEffect(() => {
    Diagnostics.message({ message: 'AdUnit useEffect', tag: 'ad prebid', data: { isReady } });
    if (!isReady) {
      return () => { };
    }
    const isCurMobile = isMobile();
    const adUnitPath = buildAdUnitPath(
      { type: adType, adRegionCode: region?.adCode, resortId: resort?.uuid },
    );

    const destroy = () => {
      if (slot.current) {
        googletag.destroySlots([slot.current]);
        slot.current = null;
      }
      if (ref.current) {
        ref.current.innerHTML = '';
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };

    const create = (currentIsMobile: boolean) => {
      const shouldDisplay = (!desktop && !mobile) || (mobile && currentIsMobile) || (desktop && !currentIsMobile);
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      if (shouldDisplay) {
        timeout.current = setTimeout(() => {
          if (ref.current) {
            createAd(
              generator(adUnitPath),
              (s) => {
                slot.current = s;
              },
              ref.current,
            );
          } else {
            Diagnostics.message({ tag: 'adunit', message: 'Ad not created as block disappeared' });
          }
        }, 200);
      }
    };

    const onResize = () => {
      const prevWasMobile = lastWidth.current < AdUnitMobileBreakPoint;
      const prevWasTablet = !prevWasMobile && lastWidth.current < AdUnitTabletBreakPoint;

      const newIsMobile = isMobile();
      const newIsTablet = isTablet();

      if (prevWasMobile !== newIsMobile || (recreateOnTablet && prevWasTablet !== newIsTablet)) {
        if (!isProductionServer) {
          Diagnostics.debug('Redoing slot', adUnitPath, prevWasMobile, newIsMobile, className);
        }
        destroy();
        create(newIsMobile);
      }
      lastWidth.current = (global.innerWidth ?? AdUnitTabletBreakPoint);
    };

    ensureGPTInitalized({
      resortId: resort?.uuid,
      resortTitle: resort?.title_short,
      regionId: region?.uuid,
      lang: getLang(),
      locale: getUnderscoreLocale(),
      m: 'n', // Constant for web
      App: 'false', // Constant for web
      t: adTKey,
      tc: `${region?.country}`,
      tr: region?.state ? (`${region?.country}_${region?.state}`) : undefined,
      Staging: isProductionServer ? 'false' : 'true',
      ...meta,
    });
    create(isCurMobile);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      destroy();
    };
  }, [isReady, region?.uuid, resort?.uuid]);

  const adUnitResortParams = useMemo(() => ({
    'data-resort': resort?.uuid,
    'data-resort-name': resort?.title_short,
    'data-language': locale,
    'data-country': region?.country_code,
  }), [locale, region, resort]);

  return (
    <div
      ref={ref}
      key={`adunit-${region?.uuid}-${region?.adCode}-${resort?.uuid}`}
      className={classNames(styles.main, className, !isProductionServer && styles.debug)}
      {...adUnitResortParams}
    />
  );
};
