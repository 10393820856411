import { GPTKeywords } from 'googlead/types';
import { isProductionServer } from 'ots-constants';
import { Diagnostics } from 'util/diagnostics';
import { formatDate } from 'util/formatDate';
import { isPrebidDisabled } from 'googlead/components/AdUnitContext/utils';
import {
  GOOGLE_AD_REFRESH_KEY,
  GOOGLE_AD_REFRESH_VALUE,
  GOOGLE_AD_SECONDS_TO_WAIT_AFTER_VIEWABILITY,
} from 'googlead/constants';
import { getLocale } from 'i18n/locale';

let initDone: boolean = false;

const NO_VALUE = '$$$NO_VALUE$$$' as any;

let last: { [id: string]: string } | undefined;

export const DEBUG_TAG = '[ensureGPTInitalized]';

const setKeywords = (keywords: { [id: string]: string }) => {
  Object.keys(keywords).forEach((key: string) => {
    if (!last || last[key] !== keywords[key]) {
      if (keywords[key] !== NO_VALUE) {
        googletag.pubads().setTargeting(key, keywords[key]);
      } else if (last && last[key] !== NO_VALUE) {
        googletag.pubads().clearTargeting(key);
      }
    }
  });

  if (!isProductionServer) {
    Diagnostics.debug('Keywords set', JSON.stringify(keywords));
  }

  last = keywords;
};

export const slotImpressionViewableHandler = (event: googletag.events.Event) => {
  const { slot } = event;
  Diagnostics.debug(DEBUG_TAG, 'impressionViewable:', slot.getSlotElementId());
  if (slot.getTargeting(GOOGLE_AD_REFRESH_KEY).indexOf(GOOGLE_AD_REFRESH_VALUE) > -1) {
    setTimeout(() => {
      Diagnostics.debug(DEBUG_TAG, 'googletag.pubads().refresh:', slot.getSlotElementId());
      googletag.pubads().refresh([slot]);
    }, GOOGLE_AD_SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
  }
};

export const ensureGPTInitalized = (setup: GPTKeywords) => {
  if (!initDone) {
    const keywords: { [id: string]: string } = { ...setup };
    Object.keys(keywords).forEach((k) => {
      if (keywords[k] === null || typeof keywords[k] === 'undefined') {
        keywords[k] = NO_VALUE;
      }
    });

    (global as any).googletag = (global as any).googletag || { cmd: [] };

    googletag.cmd.push(() => {
      Diagnostics.debug(DEBUG_TAG, 'Disabling initial load?', getLocale());
      if (!isPrebidDisabled()) {
        Diagnostics.debug(DEBUG_TAG, 'Disabling initial load');
        googletag.pubads().disableInitialLoad();
      }
      setKeywords(keywords);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.innerWidth && googletag.pubads().setTargeting('resWidth', String(window.innerWidth));
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.innerHeight && googletag.pubads().setTargeting('resHeight', String(window.innerHeight));

      googletag.pubads().enableSingleRequest();
      // googletag.pubads().collapseEmptyDivs(false);
      googletag.pubads().enableAsyncRendering();

      if (isPrebidDisabled()) {
        Diagnostics.debug(DEBUG_TAG, 'impressionViewable init', googletag.pubads().getSlots().length);
        googletag.pubads().addEventListener('impressionViewable', slotImpressionViewableHandler);
      }

      googletag.enableServices();
      Diagnostics.debug(DEBUG_TAG, 'viewport', window.innerHeight, document.body.clientHeight);
      googletag.pubads().enableLazyLoad({
        // Fetch slots within 1.2 viewports.
        fetchMarginPercent: 120,
        // Render slots within 1 viewports.
        renderMarginPercent: 100,
        // Double the above values on mobile, where viewports are smaller
        // and users tend to scroll faster.
        mobileScaling: 2.0,
      });

      // Register event handlers to observe lazy loading behavior.
      googletag.pubads().addEventListener('slotRequested', (event) => {
        Diagnostics.debug(DEBUG_TAG, 'slotRequested', event.slot.getSlotElementId());
      });

      googletag.pubads().addEventListener('slotOnload', (event) => {
        Diagnostics.debug(DEBUG_TAG, 'slotOnload', event.slot.getSlotElementId());
      });

      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        Diagnostics.debug(DEBUG_TAG, 'slotRenderEnded', event.slot.getSlotElementId(), event.isEmpty);
      });

      const d = new Date();
      Diagnostics.message({ message: 'GPT initialized', data: { date: formatDate(d, 'yyyy LLL dd HH:mm:ss') }, tag: DEBUG_TAG });
    });
    if (process.env.NEXT_PUBLIC_OPEN_GPT_CONSOLE) {
      googletag.cmd.push(() => {
        googletag.openConsole();
      });
    }

    initDone = true;
  } else {
    googletag.cmd.push(() => {
      const keywords: { [id: string]: string } = { ...setup };
      Object.keys(keywords).forEach((k) => {
        if (keywords[k] === null || typeof keywords[k] === 'undefined') {
          keywords[k] = NO_VALUE;
        }
      });
      setKeywords(keywords);
    });
  }
};
