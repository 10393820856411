import React from 'react';
import { useRouter } from 'next/router';

import { FullscreenAd } from 'googlead/components/FullscreenAd';
import { useNoHeader } from 'util/useNoHeader';
import { NewsLetterPopup } from 'googlead/components/NewsLetterPopup';
import { SizeMonitor } from 'components/size/SizeMonitor';
import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';

const getShouldSkipRenderByTemplateName = (templateName: string | undefined, locale: ELocale): boolean => {
  const skipRenderTemplates = new Set(['Resort Lodging', 'Region Lodging']);

  return (
    skipRenderTemplates.has(templateName ?? '')
    || (templateName === 'Resort Book Trip' && locale === ELocale.EN_GB)
  );
};
export const TopAd = ({ id, templateName } : { id: string, templateName?:string }) => {
  const router = useRouter();
  const elKey = router.asPath;
  const { noHeader } = useNoHeader();
  const locale = getLocale() as ELocale;

  const shouldSkipRender = noHeader || getShouldSkipRenderByTemplateName(templateName, locale);

  if (shouldSkipRender) {
    return null;
  }

  return (
    <>
      <SizeMonitor waitInteraction><FullscreenAd key={`${id}_${elKey}`} /></SizeMonitor>
      <NewsLetterPopup key={`${id}_${elKey}_2`} />
    </>
  );
};
