export enum AdPositions {
  Fullscreen = 'Fullscreen',
  Leaderboard = 'leaderboard',
  Popup = 'popup',
  RegularFirstOnPage = 'fpv',
  Regular = 'Mid',
  Skyscraper = 'Sky',
}

export type GPTKeywords = {
  locale: string,
  lang: string,
  resortId?: string,
  resortTitle?: string,
  regionId?: string,
  m: 'n', // Constant for web
  App: 'false', // Constant for web
  /**
   * Page type
   */
  t: string,
  /**
   * Tracking Country
   */
  tc: string,
  /**
   * Tracking Region (Country + State)
   */
  tr?: string,
  Staging?: 'true' | 'false',
};

export type AdPositionId = `adpos_${AdPositions}`;
