import { refreshAds } from 'googlead/refreshAds';
import { AdPositionId, AdPositions } from 'googlead/types';
import { isProductionServer } from 'ots-constants';
import { Diagnostics } from 'util/diagnostics';

let counter: number = 0;

// eslint-disable-next-line no-plusplus
export const adId = (pos: AdPositions) => `adpos_${pos}_${counter++}` as AdPositionId;

export const createAd = (defGenerator: () => googletag.Slot, setRef: (slot: googletag.Slot) => void, div: HTMLDivElement) => {
  googletag.cmd.push(() => {
    const slot = defGenerator();
    // eslint-disable-next-line no-param-reassign,no-plusplus
    div.id = slot.getSlotElementId() || adId(AdPositions.Regular);
    setRef(slot);
    if (!isProductionServer) {
      Diagnostics.debug('Requesting `googletag.display` from prebid', slot, div.id);
      Diagnostics.debug('Ad fn:', String(googletag.display || '').substring(0, 150));
    }
    googletag.display(slot.getSlotElementId());
    refreshAds();
  });
};
