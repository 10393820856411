import { getLocale } from 'i18n/locale';
import { DateTime } from 'luxon';
import { formatDateTimeShort } from 'util/formatAgo';
import { ELocale } from 'i18n/localeEnum';

export const DEFAULT_DATE_FORMAT = (() => {
  switch (getLocale() as ELocale) {
    case ELocale.EN_US:
    case ELocale.EN_GB:
      return 'yyyy LLL dd';
    case ELocale.DE_DE:
    case ELocale.PL_PL:
    case ELocale.CS_CZ:
    case ELocale.SK_SK:
    case ELocale.DA_DK:
      return 'dd. LLL yyyy';
    case ELocale.FR_FR:
    case ELocale.IT_IT:
    case ELocale.NL_NL:
    case ELocale.NN_NO:
    case ELocale.SV_SE:
      return 'dd LLL yyyy';
    case ELocale.ES_ES:
      return 'dd, LLL yyyy';
    default:
      return 'dd LLL yyyy';
  }
})();

export const SHORT_DATE_FORMAT = (() => {
  switch (getLocale() as ELocale) {
    case ELocale.EN_US:
    case ELocale.EN_GB:
      return 'LLL dd';
    case ELocale.DE_DE:
    case ELocale.PL_PL:
    case ELocale.CS_CZ:
    case ELocale.SK_SK:
    case ELocale.DA_DK:
      return 'dd. LLL';
    case ELocale.FR_FR:
    case ELocale.IT_IT:
    case ELocale.NL_NL:
    case ELocale.NN_NO:
    case ELocale.SV_SE:
      return 'dd LLL';
    case ELocale.ES_ES:
      return 'dd, LLL';
    default:
      return 'dd LLL';
  }
})();

export const WIDGET_DATE_FORMAT = (() => {
  switch (getLocale() as ELocale) {
    case ELocale.EN_US:
      return 'L/d';
    case ELocale.EN_GB:
    case ELocale.FR_FR:
    case ELocale.IT_IT:
    case ELocale.PL_PL:
    case ELocale.ES_ES:
      return 'd/L';
    case ELocale.DE_DE:
    case ELocale.CS_CZ:
    case ELocale.SK_SK:
    case ELocale.DA_DK:
    case ELocale.NN_NO:
    case ELocale.SV_SE:
      return 'd.L.';
    case ELocale.NL_NL:
      return 'd-L';
    default:
      return 'd/L';
  }
})();

export const WIDGET_DATE_TIME_FORMAT = (() => {
  switch (getLocale() as ELocale) {
    case ELocale.EN_US:
      return 'L/d t';
    case ELocale.EN_GB:
    case ELocale.FR_FR:
    case ELocale.IT_IT:
    case ELocale.PL_PL:
    case ELocale.ES_ES:
      return 'd/L T';
    case ELocale.DE_DE:
    case ELocale.CS_CZ:
    case ELocale.SK_SK:
    case ELocale.DA_DK:
    case ELocale.NN_NO:
    case ELocale.SV_SE:
      return 'd.L. T';
    case ELocale.NL_NL:
      return 'd-L T';
    default:
      return 'd/L T';
  }
})();

export const FULL_DATE_FORMAT = [ELocale.EN_US, ELocale.EN_GB].includes(getLocale() as ELocale) ? 'LLLL dd, yyyy' : 'dd LLLL, yyyy';
export const MINIMAL_DATE_FORMAT = (() => {
  switch (getLocale() as ELocale) {
    case ELocale.EN_US:
    case ELocale.EN_GB:
      return 'yyyy/MM/dd';
    case ELocale.DE_DE:
      return 'dd.MM.yyyy';
    case ELocale.FR_FR:
    case ELocale.NL_NL:
    case ELocale.DA_DK:
      return 'dd-MM-yyyy';
    case ELocale.IT_IT:
    case ELocale.ES_ES:
      return 'dd/MM/yyyy';
    case ELocale.CS_CZ:
    case ELocale.SK_SK:
    case ELocale.PL_PL:
      return 'dd. MM. yyyy';
    case ELocale.NN_NO:
      return 'dd.MM.yyyy';
    case ELocale.SV_SE:
      return 'yyyy-MM-dd';
    default:
      return 'dd-MM-yyyy';
  }
})();
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss ZZ';
export const DATE_ATOM = 'yyyy-MM-dd\'T\'HH:mm:ssZZ';
export const SHORT_DATE_W_YEAR_FORMAT = DEFAULT_DATE_FORMAT;

/**
 * @deprecated use formatDateFromStr
 */
export const formatDate = (d: Date, format: string = DEFAULT_DATE_FORMAT) => DateTime.fromJSDate(d).toFormat(format);

/**
 * Parses from date without timezone like yyyy-MM-dd or from full date
 * Note: we don't convert short date with `new Date` not to mess up timezones
 */
export const parseDate = (d: string) => ((d.length === 10) ? DateTime.fromFormat(d, 'yyyy-MM-dd') : DateTime.fromJSDate(new Date(d)));

/**
 * Parses from date without timezone like yyyy-MM-dd
 * @deprecated use 'parseDate'
 */
export const parseShortDate = (d: string) => parseDate(d);

export const formatDateFromStr = (d?: string, format: string = DEFAULT_DATE_FORMAT) => (d ? parseDate(d).toFormat(format) : '');

/**
 * Formats from date without timezone like yyyy-MM-dd
 * @deprecated use 'formatDateFromStr'
 */
export const formatFromShortDate = (d?: string, format: string = DEFAULT_DATE_FORMAT) => formatDateFromStr(d, format);

export const formatShortDateStr = (d?: string) => (d ? formatDateTimeShort(parseDate(d)) : '');
