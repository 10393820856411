import { I18nKey } from 'i18n/types';
import TimeAgo from 'javascript-time-ago';
import { DateTime } from 'luxon';
import { getCountry, getLang, getLocale } from 'i18n/locale';
import { defaultLocale } from 'i18n/constants';
import {
  formatDateFromStr, parseDate, SHORT_DATE_FORMAT, SHORT_DATE_W_YEAR_FORMAT,
} from 'util/formatDate';

export const formatAgo = (d: Date) => {
  const timeAgo = new TimeAgo([getLocale(), getLang(), defaultLocale]);
  return timeAgo.format(d, 'round');
};

export const formatDateTimeShort = (dt: DateTime) => {
  if (dt.get('year') !== DateTime.local().get('year')) {
    return dt.toFormat(SHORT_DATE_W_YEAR_FORMAT);
  }
  return dt.toFormat(SHORT_DATE_FORMAT);
};

export const formatDateShort = (d: Date) => {
  const dt = DateTime.fromJSDate(d);
  return formatDateTimeShort(dt);
};

export const formatAgoWithingHours = (d: Date, hours: number) => {
  if (d.getTime() + hours * 60 * 60 * 1000 > Date.now()) {
    return formatAgo(d) as string;
  }

  return formatDateShort(d);
};

export const formatAgoIfIn24 = (d: Date) => formatAgoWithingHours(d, 24);

export const formatAgoIfIn48 = (d: Date) => formatAgoWithingHours(d, 48);

export const formatDay = (d: string, t: (k: I18nKey['common']) => string) => {
  const inputDate: DateTime = parseDate(d);
  const currentDate: DateTime = DateTime.now();

  if (inputDate.year === currentDate.year && inputDate.month === currentDate.month) {
    if (inputDate.day === currentDate.day) {
      return t('common.dayToday');
    }

    if (inputDate.day === currentDate.day + 1) {
      return t('common.dayTomorrow');
    }

    if (inputDate.day === currentDate.day - 1) {
      return t('common.dayYesterday');
    }
  }

  return undefined;
};

export const format24Hour = (d: string, t: (k: I18nKey['common']) => string) => {
  const inputDate: DateTime = parseDate(d);
  const currentDate: DateTime = DateTime.now();

  if (inputDate.year === currentDate.year && inputDate.month === currentDate.month && inputDate.day === currentDate.day) {
    return t('region.skiReport.snowfall-24h-abb');
  }

  return undefined;
};

export const fullMonth = (d: Date) => DateTime.fromJSDate(d).monthLong;

export const TIME_FORMAT = (getCountry() === 'US') ? 'h a' : 'HH:mm';

export const formatAgoIfIn12 = (d: Date) => DateTime.fromJSDate(d).toFormat(TIME_FORMAT);

export const formatAgoTimestampOrString = (t: number | string) => formatAgo(new Date(t));

export const formatAgoIfIn24TimestampOrString = (t: number | string) => formatAgoIfIn24(new Date(t));

export const formatAgoIfIn12TimestampOrString = (t: number | string) => formatAgoIfIn12(new Date(t));

export const formatFullMonth = (t: string) => fullMonth(new Date(t));

/**
 * Formats date as 'Today' or 'Yesterday' or 'Tomorrow', than fallbacks to empty
 */
export const formatAsTodayYesterdayTomorrow = (
  text: string | undefined,
  t: (k: I18nKey['common']) => string,
) => (text ? formatDay(text, t) : '');

/**
 * Formats date as 'Today' or 'Yesterday' or 'Tomorrow', than fallbacks to short exact date
 */
export const formatAsTodYestTomExact = (
  text: string | undefined,
  t: (k: I18nKey['common']) => string,
) => (text ? (formatDay(text, t) || formatDateTimeShort(parseDate(text))) : '');

/**
 * Formats date as 'Today' or 'Yesterday' or 'Tomorrow', than fallbacks to week day
 */
export const formatAsTodYestTomWeekday = (
  text: string | undefined,
  t: (k: I18nKey['common']) => string,
) => (text ? (formatDay(text, t) || formatDateFromStr(text, 'ccc')) : '');

/**
 * Formats date as '24hr' for today, than fallbacks to week day
 */
export const formastAs24hourWeekday = (
  text: string | undefined,
  t: (k: I18nKey['common']) => string,
) => (text ? (format24Hour(text, t) || formatDateFromStr(text, 'ccc')) : '');
