import React, { useEffect, useRef, useState } from 'react';
import { useSize } from 'util/useSize';
import { IS_SSR } from 'ots-constants';
import { Diagnostics } from 'util/diagnostics';
import classNames from 'classnames';
import styles from './SizeMonitor.module.scss';

const sizeCache: Record<string, number> = {};

export const SizeMonitor = ({
  children, startingHeight, id,
}: {
  children: React.ReactNode,
  waitInteraction?: boolean,
  startingHeight?: number,
  id?: string,
}) => {
  const waitInteraction = false; // TODO: this is a false variable, use real one when needed
  const wrapperDiv = useRef<HTMLDivElement>(null);
  const controlDiv = useRef<HTMLDivElement>(null);
  const size = useSize(controlDiv);
  const [enabled, setEnabled] = useState<boolean>(id ? (!!sizeCache[id] || !waitInteraction) : !waitInteraction);
  const [height, setHeight] = useState<number>(id ? sizeCache[id] || (startingHeight || 0) : startingHeight ?? 0);

  useEffect(() => {
    if (!IS_SSR) {
      if (waitInteraction) {
        Diagnostics.debug('[SizeMonitor]: Hidden by default, triggers on user interaction', id, id ? sizeCache[id] : 'not persisted');
      } else {
        Diagnostics.debug('[SizeMonitor]: Shown by default, triggers immediately', id, id ? sizeCache[id] : 'not persisted');
      }
    }
  }, [waitInteraction]);

  useEffect(() => {
    if (!IS_SSR) {
      const enableAd = (e: unknown) => {
        setEnabled((v) => {
          if (!v) {
            Diagnostics.debug('[SizeMonitor]: ENABLING', e);
          }
          return true;
        });
      };
      window.addEventListener('touchstart', enableAd);
      window.addEventListener('click', enableAd);
      window.addEventListener('mousemove', enableAd);

      return () => {
        window.removeEventListener('touchstart', enableAd);
        window.removeEventListener('click', enableAd);
        window.removeEventListener('mousemove', enableAd);
      };
    }
    return () => undefined;
  }, []);

  useEffect(() => {
    if (!IS_SSR) {
      Diagnostics.debug('[ScrollHeight]: HEIGHT', size?.height, enabled);
      if (enabled && typeof (size?.height) !== 'undefined') {
        setHeight(size?.height);
        if (id) {
          sizeCache[id] = size?.height;
        }
      }
    }
  }, [size?.height, enabled, id]);

  return (
    <div
      ref={wrapperDiv}
      className={classNames(styles.wrapper, { [styles.active]: !!height })}
      style={{ height: `${height}px` }}
    >
      <div ref={controlDiv} className={styles.inner}>
        {children}
      </div>
    </div>
  );
};
