import { isPrebidDisabled } from 'googlead/components/AdUnitContext/utils';

let timeout: NodeJS.Timeout | null = null;

export const refreshAds = () => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    if (isPrebidDisabled()) {
      // googletag.pubads().refresh();
    } else {
      const { OTSPrebid } = window as any;
      if (OTSPrebid && OTSPrebid.showAds) {
        OTSPrebid.showAds();
      }
    }
  }, isPrebidDisabled() ? 1000 : 500);
};
