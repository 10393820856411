import useResizeObserver from '@react-hook/resize-observer';
import * as React from 'react';

export const pxToNumber = (px: string) => (+px.split('px').join(''));

export const getContentSize = <T extends HTMLElement>(target: T) => {
  const withPaddings = target.getBoundingClientRect();
  const calc = getComputedStyle(target);
  const width = pxToNumber(calc.getPropertyValue('width'));
  const height = pxToNumber(calc.getPropertyValue('height'));
  const pL = pxToNumber(calc.getPropertyValue('padding-left'));
  const pR = pxToNumber(calc.getPropertyValue('padding-right'));
  const pT = pxToNumber(calc.getPropertyValue('padding-top'));
  const pB = pxToNumber(calc.getPropertyValue('padding-bottom'));
  if (calc.getPropertyValue('box-sizing') === 'border-box') {
    return {
      ...withPaddings,
      width: width - pL - pR,
      height: height - pT - pB,
    };
  }
  return withPaddings;
};

export const getSize = <T extends HTMLElement>(target: React.RefObject<T> | T | null) => {
  if (target instanceof HTMLElement) {
    return getContentSize(target);
  } if (target?.current instanceof HTMLElement) {
    return getContentSize(target.current);
  }
  return undefined;
};

export const useSize = <T extends HTMLElement>(target: React.RefObject<T> | T | null) => {
  const [size, setSize] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    const newSize = getSize(target);
    setSize(newSize);
  }, [target]);

  useResizeObserver(target, (entry) => {
    setSize(entry.contentRect);
  });
  return size;
};
