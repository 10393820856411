import { AdUnit, AdUnitClasses } from 'googlead/components/AdUnit';
import { GPTAds } from 'googlead/GPTAds';
import { AdPositions } from 'googlead/types';
import React from 'react';

export const FullscreenAd = () => (
  <AdUnit
    generator={GPTAds[AdPositions.Fullscreen]}
    className={AdUnitClasses.fullscreen}
  />
);
