import React from 'react';
import { GPTAds } from 'googlead/GPTAds';
import { AdPositions } from 'googlead/types';
import { AdUnit, AdUnitClasses } from 'googlead/components/AdUnit';

export const NewsLetterPopup = () => (
  <AdUnit
    generator={GPTAds[AdPositions.Popup]}
    className={AdUnitClasses.popup}
  />
);
