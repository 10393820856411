import { IWithChildren } from 'types';
import classNames from 'classnames';
import styles from './styles.module.scss';

export function Layout({
  children, className,
}: { className?: string } & IWithChildren) {
  return (
    <div className={classNames(styles.layout, className)}>
      {children}
    </div>
  );
}
