import { getDFPTag } from 'util/getPortalConfig';

export const UNIT_BASE = '/7730';

export type AdUnitType = 'resort' | 'region' | 'news' | 'home' | 'mobile' | 'other' | 'gallery' | 'videos' | 'hotel' | 'popup';

export type AdUnitPage = 'profile';

export type AdUnitPathOptions = { resortId?: string, adRegionCode?: string, type: AdUnitType };

export const buildAdUnitPath = ({ type, resortId, adRegionCode }:AdUnitPathOptions) => {
  // custom oas page urls for pages within groups or directories
  // note the starting /7730 we want a uri at the end of it all.'

  const urlParts = [UNIT_BASE, getDFPTag()];
  if (type === 'resort') {
    if (adRegionCode) {
      urlParts.push(adRegionCode);
    }
    if (resortId) {
      urlParts.push(resortId);
    }
  } else if (type === 'region') {
    if (adRegionCode) {
      urlParts.push(adRegionCode);
    }
  } else if (type === 'news') {
    urlParts.push('news');
  } else if (type === 'gallery') {
    urlParts.push('gallery');
  } else if (type === 'videos') {
    urlParts.push('gallery/video');
  } else if (type === 'popup') {
    urlParts.push('popup');
  } else if (type === 'hotel') {
    // push @$urlParts, $self - > _urlHotel($page);
  } else {
    urlParts.push(type);
  }

  return urlParts.join('/');
};
